import React from "react"
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api"

export default function Map () {
    const apiKey = 'AIzaSyCkS81LIZdJVJhYPuhUvP8zjGgOUU88Qls'

    const containerStyle = {
        height: '500px',
        width: '450px'
    }

    const center = {
        lat: -33.4380251,
        lng: -70.5718771
    }

    const onLoad = marker => {
        console.log(marker)
    }

    return(
        <LoadScript
            googleMapsApiKey={apiKey}
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={14}
            >
                <Marker
                    position={center}
                />
            </GoogleMap>
        </LoadScript>   
    )
}