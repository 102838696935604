import React from "react"
import logo from '../img/logo-vm-home.jpg'
import styles from'./Footer.module.css'

export default function Footer () {
    return (
        <>
            <div className={styles.container}>
                <span className={styles['container-span']} id={styles['vm-ing']}>
                    <img src={logo} id={styles.logo} alt='' />
                    <div>VM Ingeniería y Automatización Industrial &copy;</div>
                    <div>Principe de Gales 5921, La Reina</div>
                    <div>Horario: Lunes a viernes 8:30 a 17:00</div>
                </span>

                <br />

                <span className={styles['container-span']} id={styles.servicios}>
                    <h2>Servcios</h2>
                    <div>Ingeniería</div>
                    <div>Potencia</div>
                    <div>Automatización y Control</div>
                    <div>Accionamientos</div>
                    <div>Mantenimiento</div>
                </span>

                <br />

                <span className={styles['container-span']} id={styles.contacto}>
                    <h2>Contacto</h2>
                    <div>Luis Cofré</div>
                    <div>+56 9 4041 7108</div>
                    <div>luis.cofre@vm-ingenieria.cl</div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </span>

            </div>
            <div className={styles.imagenes}>
                <a href="https://www.flaticon.com/free-icons/technology" title="technology icons">Technology icons created by Eucalyp - Flaticon  ||</a>
                <a href="https://www.flaticon.com/free-icons/mechanism" title="mechanism icons">  Mechanism icons created by Freepik - Flaticon  ||</a>
                <a href="https://www.flaticon.com/free-icons/production" title="production icons">  Production icons created by Freepik - Flaticon</a>
            </div>
        </>
    )
}