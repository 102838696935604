import React from "react"
import { Link, NavLink } from "react-router-dom"
import logo from '../img/logo-vm-home.jpg'
import styles from './NavBar.module.css'

export default function NavBar () {
    const activeClassName = ({isActive}) => isActive && styles.active
    
    const [ activeBurguer, setActiveBurguer ] = React.useState(
        {
            active: false,
            line1: styles.line1,
            line2: styles.line2,
            line3: styles.line3
        }
    )

    const burguerHandler = () => {
        if (!activeBurguer.active) {
            setActiveBurguer(
                {
                    active: true,
                    line1: styles.activeLine1,
                    line2: styles.activeLine2,
                    line3: styles.activeLine3
                }
            )
        } else {
            setActiveBurguer(
                {
                    active: false,
                    line1: styles.line1,
                    line2: styles.line2,
                    line3: styles.line3
                }
            )
        }
    }

    
    return (
        <div id={styles.navBar}>
            <Link to='/'>
                <img src={logo} alt="ir a home" id={styles.logo} />
            </Link>

            <div className={activeBurguer.active ? styles.activeBotonera : styles.botonera}>
                <div><NavLink onClick={activeBurguer.active && burguerHandler} className={`${styles.primero} ${activeClassName}`} to='/sobre-nosotros' >Sobre_nosotros</NavLink></div>
                <div><NavLink onClick={activeBurguer.active && burguerHandler} className={activeClassName} to='/servicios' >Servicios</NavLink></div>
                <div><NavLink onClick={activeBurguer.active && burguerHandler} className={activeClassName} to='/contactanos' >Contactanos</NavLink></div>
            </div>

            <div className={styles.hamburguesa} onClick={burguerHandler}>
                <span className={activeBurguer.line1}></span>
                <span className={activeBurguer.line2}></span>
                <span className={activeBurguer.line3}></span>
            </div>
        </div>
    )
}