import React, { useState } from "react"
import servicio1 from '../../img/new ingenieria.png'
import servicio2 from '../../img/new integracion.png'
import servicio3 from '../../img/new puesta en marcha.png'
import ServiceCard from "./ServiceCard"
/* import servicio4 from '../../img/Imagen4.png'
import servicio5 from '../../img/Imagen5.png'
import servicio6 from '../../img/Imagen6.png' */
import styles from './Servicios.module.css'
import { Link } from "react-router-dom"

export default function Servicios () {
    const servicios = [
        {
            servicio: 'Ingeniería',
            imagen: servicio1,
            descripcion: ['Conceptual', 'Básica', 'Detalle', 'Asesorías técnicas']
        },
        {
            servicio: 'Integración',
            imagen: servicio2,
            descripcion: ['Sistema de Control', 'Instrumentación', 'Tableros eléctricos', 'Montaje', 'Accionamientos']
        },
        {
            servicio: 'Puesta en servicio',
            imagen: servicio3,
            descripcion: ['Comisionamiento', 'Pruebas en vacío', 'Pruebas operacionales']
        },
/*         {servicio: '', imagen: servicio4},
        {servicio: '', imagen: servicio5},
        {servicio: '', imagen: servicio6}, */
    ]
    
    function x () {
        let state = {}
        servicios.map(servicio => state[servicio.servicio] = false)
        return state
    }

    const [ isClicked, setIsClicked ] = useState(x())

    const clickHandler = (e) => {
        const id = e.target.classList[0]
        console.log(id)
        setIsClicked({
            ...isClicked,
            [e.target.id]: true
        })
    }

    return (
        <div className={styles.container}>
            <div className={styles.servicios}>
                {servicios.map((servicio, i) => {
                    return(
                        <span key={i} className={styles.servicio}>
                            <>
                                <div id={`servicio${i}`} className={`${styles.face} ${styles.front}`}>
                                    <img className={`${styles.servicioImg}`} src={servicio.imagen} alt="" />
                                    <h2>{servicio.servicio}</h2>
                                </div>
                            </>

                            <div id={servicio.servicio} className={`servicio${i} ${styles.face} ${styles.back}`} onClick={clickHandler}>
                                <h2 id={servicio.servicio} className={`servicio${i} ${styles.servicioTitulo}`}>{servicio.servicio}</h2>
                                <p className={`servicio${i}`} id={servicio.servicio}>
                                        <ul><br/>
                                            {servicio.descripcion.map(serv => {
                                                    return (
                                                        <li className={styles.listElement}>{serv}</li>
                                                    )
                                            })}
                                        </ul>
                                </p>
                            </div>
                        </span>
                    )
                })}
            </div>

            {/* <h1>Descripcion</h1>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed reiciendis amet nostrum consectetur iusto iure saepe dolor sit. Repudiandae totam non vero veniam doloremque nostrum corporis ad omnis numquam qui.</p><br />
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed reiciendis amet nostrum consectetur iusto iure saepe dolor sit. Repudiandae totam non vero veniam doloremque nostrum corporis ad omnis numquam qui.</p><br />
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed reiciendis amet nostrum consectetur iusto iure saepe dolor sit. Repudiandae totam non vero veniam doloremque nostrum corporis ad omnis numquam qui.</p> */}
        </div>
    )
}