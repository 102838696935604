import React from "react"
import siemens from '../img/siemens.jpg'
import styles from './SobreNosotros.module.css'

export default function SobreNosotros () {
    return (
        <div>
            <img src={siemens} id={styles.fotito} />
            <div className={styles.body}>
                <h1 className={styles.h1}>Automatización Industrial</h1>
                <p className={styles.parrafo}>
                    <span className={styles.descripcion}>Somos una empresa dedicada a nuestros clientes, nuestro horizonte es realizar un trabajo de máxima calidad con un equipo de expertos con valores intelectuales, y sociales destacados. Una empresa se basa en sus colaboradores, su expertiz en su desarrollo profesional y humano, VM-Ingeniería ofrece en sus servicios la mejor tecnología con una calidad sobresaliente.<br/><br/></span>
                    
                    <div className={styles.misionVision}>
                        <span className={`${styles.span} ${styles.mision}`}>
                            <h3>Misión</h3>
                            <p className={styles.p}>
                                Entregar resultados exitosos para nuestros clientes en el desarrollo de sus proyectos, en los cuales participamos activamente ofreciendo servicios y soluciones de alta calidad, los cuales cumplan con todas las exigencias técnicas y económicas, además procuramos mantener una relación duradera en el tiempo.
                            </p>
                        </span><br />

                        <span className={`${styles.span} ${styles.vision}`}>
                            <h3>Visión</h3>
                            <p className={styles.p}>
                                Ser reconocidos por nuestros clientes y colaboradores como una empresa líder en desarrollo de proyectos de ingeniería, en todas sus etapas, de las disciplinas de Instrumentación y Electricidad.
                            </p>
                        </span>
                    </div>
                </p>
            </div>
        </div>)
}