import { Routes, Route } from 'react-router-dom'
import SobreNosotros from './components/SobreNosotros.jsx'
import Contactanos from './components/Contactanos.jsx'
import Servicios from './components/servicios/Servicios'
import Footer from './components/Footer'
import NavBar from './components/NavBar'
import Home from './components/Home'
import ServiceCard from './components/servicios/ServiceCard.jsx'
import styles from './App.modules.css'

function App() {
  return (
    <div className="App">
      <div className={styles.nav}>
        <NavBar />
      </div>

      <br/> <br/> <br/> <br/>

      <div className={styles.body}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/sobre-nosotros' element={<SobreNosotros />} />
          <Route path='/servicios' element={<Servicios />} />
          <Route path='/contactanos' element={<Contactanos />} />
        </Routes>
      </div>
      
      <div className='footer'>
        <Footer />
      </div>
    </div>
  )
}

export default App