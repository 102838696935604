import styles from './Contactanos.module.css'
import React, { useRef, useState } from "react"
import emailjs from '@emailjs/browser'
import Map from "./Map"

export default function Contactanos () {
    const mailRegexp = new RegExp(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/)
    const error = styles.error
    const form = useRef()

    const [ state, setState ] = useState({
        nombre: '',
        email: '',
        mensaje: '',
    })

    const [ errors, setErrors ] = useState({
        nombre: '',
        email: '',
    })

    const handlingChange = (e) => {
        const changeName = e.target.name
        const changeValue = e.target.value

        setState({
            ...state,
            [changeName]: changeValue
        })

        if (changeName === 'email') {
            if (!mailRegexp.test(changeValue)) {
                setErrors({
                    ...errors,
                    email: 'Debe ingresar un correo válido'
                })
            }
            else setErrors({
                ...errors,
                email:''
            })
        }
        
        if (changeName === 'nombre') {
            if (changeValue.trim().replace(/\s+/, ' ').split(' ').length < 2) {
                setErrors({
                    ...errors,
                    nombre: 'Debe ingresar nombre y apellido'
                })
            }
            else setErrors({
                ...errors,
                nombre:''
            })
        }
    }

    const handlingSubmit = e => {
        e.preventDefault()

        const serviceId = 'service_5zq4x9k'
        const templateId = 'template_bf0hdgt'
        const publicApiKey = 'PbCUpeanaH6mLth3r'

        emailjs.sendForm(serviceId, templateId, form.current, publicApiKey)
            .then(result => {
                window.alert('Mensaje enviado correctamente. Vamos a contactarte a la brevedad a través de tu correo electrónico. Muchas gracias.')
                setState({
                    nombre: '',
                    email: '',
                    mensaje: '',
                })
                console.log(result.text)})
            .catch(error => {
                window.alert('Algo salió mál. Intentalo nuevamente o mandanos un correo a carlos.gonzalez@vm-ingenieria.cl. Perdón por la molestia.')
                console.log(error.text)})
    }

    return (
        <div className={styles.container}>
            <Map />
            <form ref={form} onSubmit={handlingSubmit}>
                <label htmlFor="nombre">Nombre: </label><br />
                <input type="text" name='nombre' id='nombre' placeholder="Juan Perez" className={errors.nombre && error} value={state.nombre} onChange={handlingChange} required />
                <br />
                {errors.nombre && <span className={styles.errorText}>{errors.nombre}</span>}
                <br />

                <label htmlFor="email">E-mail: </label><br />
                <input type="text" name='email' id='email' placeholder="juan.perez@example.com" className={errors.email && error} value={state.email} onChange={handlingChange} required />
                <br />
                {errors.email && <span className={styles.errorText}>{errors.email}</span>}
                <br />

                <label htmlFor="mensaje">Mensaje: </label>
                <br />
                <textarea name='mensaje' id='mensaje' placeholder="Escribe aquí tu consulta o pide tu cotización." value={state.mensaje} onChange={handlingChange} cols='90' rows='15' required />
                <br /><br />

                <button>Enviar</button>
            </form>
        </div>)
}