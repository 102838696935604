import React from "react"
import ReactPlayer from 'react-player'
import video from '../video/pexels-cottonbro-5532773.mp4'
import styles from './Home.module.css'

export default function Home () {
    return (
        <div className={styles.container}>
            <div className={styles.body}>
                <ReactPlayer url={video} muted={true} playing='true' width={'100%'} height={'70vh'} loop='true' />
                <h1 className={styles.h1}>Ingeniería Industrial</h1><br />
                <p className={styles.parrafo}>
                    <span>La ingeniería nos mueve, ingenieros formados en la Universidad de Santiago de Chile, con vínculos activos en el desarrollo profesional de las nuevas generaciones de profesionales para el país. Nuestro staff de profesionales realiza activamente pedagogía en control automático, accionamiento eléctrico, sistemas de potencia e intrumentación de procesos industriales.</span>
                </p><br /><br /><br />
            </div>
        </div>)
}